import { attLabel } from 'core/labels';
import { ORDER_TYPE, PmEngagementType } from '@EngagementsSowData/sowData.model';
// eslint-disable-next-line no-unused-vars
import { IOrderData, IParsedOrderData } from 'pages/WirelineEngagements/WirelineEngagementsTables/wirelineEngagementsTables.model';

interface IRowData {
  tsd: string
  pmEngagementType: string | null
  contractOrderSupport: string | null
}

export const checkIfOrderNonAttTSD = (
  rowData: IRowData,
) => rowData.tsd !== attLabel;

export const isOrderNonAttForRoles = (row: IRowData, roles: boolean[]) => roles
  .some((item) => item)
 && checkIfOrderNonAttTSD(row);

export const isActiveNumbersItemCanceled = (
  row: IRowData,
) => row?.contractOrderSupport === ORDER_TYPE.CANCELED
|| row?.pmEngagementType === PmEngagementType.CANCELED;

export const getParsedRowData = (
  {
    isEditable,
    rowData = {},
    includedFields = [],
  }:
   {
    isEditable: boolean
    rowData: IOrderData,
    includedFields?: string[],
  }): IParsedOrderData => Object.entries(rowData)
  .reduce((value, [key, data]) => (
    (data.editable === isEditable) || includedFields.includes(key)
      ? { ...value, [key]: data.value }
      : value),
  {});
