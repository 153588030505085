import { camelCase, isPlainObject } from 'lodash';
// eslint-disable-next-line no-unused-vars
import { IOpenapiSchemasParsedItem } from 'core/models/openapi.models';
// eslint-disable-next-line no-unused-vars
import { IParsedSchemaDataItem } from 'pages/WirelineEngagements/shared/OrderDataSidebar/OrderDataSidebar.model';
import { IParsedOrderData } from 'pages/WirelineEngagements/WirelineEngagementsTables/wirelineEngagementsTables.model';

export const getLabelesFromShema = (
  shema: IOpenapiSchemasParsedItem[],
  startTitle?: string,
  startFieldName?: string,
) => shema
  .reduce((data, {
    title, properties, fieldName, description,
  }) => {
    const fullTitle = startTitle ? `${startTitle} ${title}` : title;
    const fullFieldName = startFieldName ? camelCase(`${startFieldName}_${fieldName}`) : fieldName;
    if (properties) {
      const nestedLabelesData = getLabelesFromShema(properties, fullTitle, fullFieldName);
      return { ...data, ...nestedLabelesData };
    }

    return { ...data, [fullFieldName]: { description, label: fullTitle } };
  }, {});

const getLabelFromDescription = (description: string, key: string | null) => {
  if (!key) return key;

  const regex = new RegExp(`\\*\\s\`?${key}\`?\\s-\\s(.*)`);
  const match = description.match(regex);

  return match ? match[1].trim() : key;
};

export const getOrderDataFromMeta = (
  meta: IParsedSchemaDataItem = {},
  data: IParsedOrderData,
  path?: string,
) => Object.entries(data)
  .map(([key, value]) => {
    const fullKey = path ? camelCase(`${path}_${key}`) : key;

    const isNestedObject = isPlainObject(value);
    if (isNestedObject) {
      return getOrderDataFromMeta(meta, value as IParsedOrderData, fullKey);
    }

    const metaItemData = meta[fullKey];
    const label = metaItemData?.label || fullKey;
    const parsedValue = metaItemData?.description
      ? getLabelFromDescription(metaItemData.description, value as string)
      : value;

    return { label, value: parsedValue };
  })
  .flat();
