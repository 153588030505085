import styles from 'assets/styles/_resources.scss';

export const colorsOfTotalCharts = [
  styles.primaryColor,
  styles.darkColor100,
  styles.blueColor,
  styles.tealColor500,
  styles.secondaryColor,
  styles.yellowColor500,
];

export const totalChartFields = {
  new: 'New',
  canceled: 'Canceled',
  inProgress: 'In Progress',
  completed: 'Completed',
  reOrder: 'Re-Order',
};

export const notifierMessage = {
  engagementsTotals: {
    error: 'Failed to complete your request for Engagement Total Orders, please try again later.',
  },
  engagementsByPeriod: {
    error: 'Failed to complete your request for Engagements By Period Orders, please try again later.',
  },
  engagementsMrc: {
    error: 'Failed to complete your request for Engagement for MRC, please try again later.',
  },
  engagementsRevenue: {
    error: 'Failed to complete your request for Engagement Revenue, please try again later.',
  },
};
