import { useIsUserType } from 'core/hooks/useIsUserType';
import useIsUserTeamRoleName from 'core/hooks/useIsUserTeamRoleName';
import useIsTeamRolesDepartmentMember from 'core/hooks/useIsTeamRolesDepartmentMember';
import { UserTeamRoleName, UserTeamRoleTeamDepartment, UserType } from 'pages/UserManagement/UserManagement.models';

const { DEPARTMENT_HEAD } = UserTeamRoleName;
const { SELLER } = UserTeamRoleTeamDepartment;
const {
  CONTRACT_SPECIALIST, BILLING_SPECIALIST, PROJECT_MANAGER, RETENTION_MANAGER,
} = UserTeamRoleTeamDepartment;

export const useUserPermissions = () => {
  const isProjectManager = useIsTeamRolesDepartmentMember(PROJECT_MANAGER);
  const isContractSpecialist = useIsTeamRolesDepartmentMember(CONTRACT_SPECIALIST);
  const isBillingSpecialist = useIsTeamRolesDepartmentMember(BILLING_SPECIALIST);
  const isRetentionManager = useIsTeamRolesDepartmentMember(RETENTION_MANAGER);
  const isSeller = useIsTeamRolesDepartmentMember(SELLER);
  const isDepartmentHead = useIsUserTeamRoleName(DEPARTMENT_HEAD);
  const isSellerDepartmentHead = isSeller && isDepartmentHead;

  const isAdmin = useIsUserType(UserType.ADMIN);
  const isTechnicalOverlay = useIsUserTeamRoleName(UserTeamRoleName.TECHNICAL_OVERLAY);

  const isProjectManagerDepartmentHead = isProjectManager && isDepartmentHead;
  const isProjectManagerNotDepartmentHead = isProjectManager && !isDepartmentHead;

  const isVisibleOrderDataSidebar = isAdmin
   || isSeller
   || isContractSpecialist
   || isTechnicalOverlay
   || isProjectManagerDepartmentHead;

  const isActionsUnavailable = isRetentionManager
   || isBillingSpecialist
   || isProjectManagerNotDepartmentHead;
  const isRequiredATTRoles = isContractSpecialist
   || isBillingSpecialist
   || isProjectManagerNotDepartmentHead;
  const isUnavailableAddOrderButton = isBillingSpecialist
   || isContractSpecialist
   || isRetentionManager
   || isProjectManagerNotDepartmentHead;
  const isDuplicateButtonDisabledByUserRole = !(
    isTechnicalOverlay
    || isAdmin
    || isSeller
  );

  return {
    isAdmin,
    isProjectManager,
    isTechnicalOverlay,
    isRequiredATTRoles,
    isRetentionManager,
    isBillingSpecialist,
    isActionsUnavailable,
    isContractSpecialist,
    isSellerDepartmentHead,
    isVisibleOrderDataSidebar,
    isUnavailableAddOrderButton,
    isDuplicateButtonDisabledByUserRole,
  };
};
