import React, {
  useState, createContext, useContext, useMemo, useEffect,
} from 'react';
import calculatePeriod from 'core/utilities/dateUtilities/calculatePeriod';
import { PERIOD_SELECT } from 'shared/PeriodAutocomplete/PeriodAutocomplete.models';
import periodMapping from 'shared/PeriodAutocomplete/constants';
import { TPeriod, TSetPeriod } from 'shared/CustomRangePicker/CustomRangePicker.models';
import { HTTPService } from 'core/services';
import { getEscalationSupportTotalAction } from '@dashboardEscalationSupport/EscalationSupportCharts/Charts/Total/GetEscalationSupportTotal';
import { useAppDispatch } from 'core/store';
import useNotifiers from 'shared/Notifier/UseNotifiers';
import { notifierMessage } from '@dashboardEscalationSupport/EscalationSupportCharts/constants';
import {
  getEscalationSupportEscalationTypeAction,
} from '@dashboardEscalationSupport/EscalationSupportCharts/Charts/EscalationType/GetEscalationSupportEscalationType';

interface IEscalationSupportChartsContext {
  getEscalationSupportData: TSetPeriod
  period: TPeriod;
}

interface IEscalationSupportChartsProviderProps {
  children: React.ReactNode;
}

const EscalationSupportChartsContext = createContext<IEscalationSupportChartsContext>(
  {} as IEscalationSupportChartsContext,
);

let escalationSupportTotalController = HTTPService.getController();
let escalationSupportEscalationTypeController = HTTPService.getController();

const EscalationSupportChartsProvider: React.FC<
  IEscalationSupportChartsProviderProps
> = ({ children }) => {
  const defaultPeriod = calculatePeriod(periodMapping, PERIOD_SELECT.ONE_MONTH);
  const [period, setPeriod] = useState(defaultPeriod);

  const dispatch = useAppDispatch();

  const { showErrorNotifier } = useNotifiers();

  const cancelGetEscalationSupportTotalRequest = () => {
    HTTPService.cancelRequest(escalationSupportTotalController);
  };

  const cancelGetEscalationSupportEscalationTypeRequest = () => {
    HTTPService.cancelRequest(escalationSupportEscalationTypeController);
  };

  const cancelRequests = () => {
    cancelGetEscalationSupportTotalRequest();
    cancelGetEscalationSupportEscalationTypeRequest();
  };

  const getEscalationSupportTotal = async (newPeriod: TPeriod) => {
    cancelGetEscalationSupportTotalRequest();

    escalationSupportTotalController = HTTPService.getController();
    try {
      await dispatch(getEscalationSupportTotalAction({
        controller: escalationSupportTotalController, params: newPeriod,
      })).unwrap();
    } catch (e) {
      showErrorNotifier(notifierMessage.escalationSupportTotal.error, e);
    }
  };

  const getEscalationSupportEscalationType = async (newPeriod: TPeriod) => {
    cancelGetEscalationSupportEscalationTypeRequest();

    escalationSupportEscalationTypeController = HTTPService.getController();
    try {
      await dispatch(getEscalationSupportEscalationTypeAction({
        controller: escalationSupportEscalationTypeController, params: newPeriod,
      })).unwrap();
    } catch (e) {
      showErrorNotifier(notifierMessage.escalationSupportType.error, e);
    }
  };

  const getEscalationSupportData = (newPeriod: TPeriod) => {
    getEscalationSupportTotal(newPeriod);
    getEscalationSupportEscalationType(newPeriod);
    setPeriod(newPeriod);
  };

  useEffect(() => {
    getEscalationSupportData(period);

    return () => cancelRequests();
  }, []);

  const value = useMemo(() => ({
    getEscalationSupportData,
    period,
  }), [period]);

  return (
    <EscalationSupportChartsContext.Provider value={value}>
      {children}
    </EscalationSupportChartsContext.Provider>
  );
};

export const useEscalationSupportChartsContext = () => useContext(EscalationSupportChartsContext);

export default EscalationSupportChartsProvider;
